import Backendless from 'backendless';
import React from 'react';
import { useState } from "react";
import AdminPanel from '../components/ui/AdminPanel';
import LoginPanel from '../components/ui/LoginPanel';


const Website = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const checkIsUserLoginValid = () => {
    return Backendless.UserService.getCurrentUser()
    .then( function( user ) {
      setIsLoading(false);
      setIsLoggedIn(user!=null);
    }).catch(error => {
      setIsLoading(false);
      setIsLoggedIn(false);
      console.log(error);
      if(error!=null && error.toString().indexOf("Session timeout")!==-1)
        Backendless.UserService.logout(); //Auto logout user

    });
  }

  const onLogin = () => {
    setIsLoggedIn(true);
  }

  const onLogout = () => {
    setIsLoggedIn(false);
  }

  //Check if user login vale
  checkIsUserLoginValid();

  return (
      <div>
        <div className="white-mask"></div>
        {!isLoading && !isLoggedIn && <LoginPanel onLogin = {onLogin} />}
        {!isLoading && isLoggedIn && <AdminPanel onLogout = {onLogout} mode = "WEBSITE"/>}
      </div>
    );
  }

export default Website  ;