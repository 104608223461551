import './App.css';
import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Backendless from 'backendless';
import Admin from './pages/Admin';
import Website from './pages/Website';
const { REACT_APP_BACKENDLESS_SERVER_URL,REACT_APP_BACKENDLESS_APP_ID,REACT_APP_BACKENDLESS_API_KEY } = process.env;
Backendless.serverURL = REACT_APP_BACKENDLESS_SERVER_URL;
Backendless.initApp(REACT_APP_BACKENDLESS_APP_ID, REACT_APP_BACKENDLESS_API_KEY);

function App() {

  return (
    <Router>
        <Switch>
          <Route exact path="/" component={Admin} />
          <Route exact path="/website" component={Website} />
        </Switch>
      </Router>
 );
}

export default App;