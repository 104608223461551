import React from 'react';

const MainMenu = ({mode}) => {
  const adminSelected = mode === "ADMIN" ? "selected" : "";
  const websiteSelected = mode === "WEBSITE" ? "selected" : "";
  return  (
      <div className="main-menu">
            <span><b>Manage: </b></span>
            <div className={adminSelected}><a id="room-href" href="/#/">Room</a></div>
            <div className={websiteSelected}><a id="website-href" href="#/website">Website</a></div>
      </div>
    );
}
export default MainMenu;