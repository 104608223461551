import React, { useState, useRef } from "react";
//import TimezoneSelect, {allTimezones} from "react-timezone-select";
//import moment from "moment-timezone";
import Backendless from 'backendless';
import VideoPlayer from "../yoyuma-common/videoplayer/VideoPlayer";
import Unsplash , { InsertIntoApplicationUploader } from "../yoyuma-common/Unsplash";
import ReactPlayer from "react-player";
const { REACT_APP_UNSPLASH_ACCESS_KEY, REACT_APP_DEFAULT_TITLE,REACT_APP_ROOM_NAME_VAR,REACT_APP_DEFAULT_WELCOME,REACT_APP_DEFAULT_DESCRIPTION,REACT_APP_DEFAULT_ROOM_FORM_TITLE,REACT_APP_DEFAULT_ROOM_FORM_URL_MESSAGE,REACT_APP_DEFAULT_ROOM_FORM_NAME_MESSSAGE } = process.env;
const SysConifg = () => {

  const ROOM_DEFAULT_INFO = "room_default_info";
  const ROOM_ADD_PAGE_INFO = "room_ad_page_info";
  const urlInput = useRef();
  const nameInput = useRef();

  // State
  const [isLoading, setIsLoading] = useState(false);
  const [defaultConfiguration, setDefaultConfiguration] = useState();
  const [defaultAdPageConfiguration, setDefaultAdPageConfiguration] = useState();

  //const [selectedTimezone, setSelectedTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone)
  const [welcome, setWelcome] = useState(REACT_APP_DEFAULT_WELCOME);
  const [title, setTitle] = useState(REACT_APP_DEFAULT_TITLE);
  const [description, setDescription] = useState(REACT_APP_DEFAULT_DESCRIPTION);
  const [content, setContent] = useState([]);
  const [backgrounds, setBackgrounds] = useState([]);

  const [hostStartDelay, setHostStartDelay] = useState(5);
  const [startDelay, setStartDelay] = useState(10);
  const [immediateStartDelay, setImmediateStartDelay] = useState(30);
  const [breathCycle, setBreathCycle] = useState(6);
  const [breathsTransparancy, setBreathsTransparancy] = useState(60);
  
  const [formRoomTitle, setFormRoomTitle] = useState(REACT_APP_DEFAULT_ROOM_FORM_TITLE);
  const [formRoomUrlMessage, setFormRoomUrlMessage] = useState(REACT_APP_DEFAULT_ROOM_FORM_URL_MESSAGE);
  const [formRoomNameMessage, setFormRoomNameMessage] = useState(REACT_APP_DEFAULT_ROOM_FORM_NAME_MESSSAGE);

  const [pagetitle, setPageTitle] = useState("");
  const [pagecontent, setPageContent] = useState("");
  const [pagescript, setPageScript] = useState("");
  const [startdescription, setStartDescription] = useState("");
  const [pageShow, setPageShow] = useState(true);
  const [pageEnable, setPageEnable] = useState(true);
  const [bgRotation, setBgRotation] = useState(60);
  const [bgDate, setBgDate] = useState(null);
  const [selectURL, setSelectURL] = useState(null);  
  
  const onHostStartDelayChange = (event) => {
    setHostStartDelay(event.target.value);
  }
  const onStartDelayChange = (event) => {
    setStartDelay(event.target.value);
  }
  const onImmediateStartDelayChange = (event) => {
    setImmediateStartDelay(event.target.value);
  }
  const onBreathCycleChange = (event) => {
    setBreathCycle(event.target.value);
  }

  const onWelcomeChange = (event) => {
    setWelcome(event.target.value);
  }

  const onTitleChange = (event) => {
    setTitle(event.target.value);
  }

  const onDescriptionChange = (event) => {
    setDescription(event.target.value);
  }

  const onFormRoomTitleChange = (event) => {
    setFormRoomTitle(event.target.value);
  }

  const onBreathsTransparencyChange = (event) => {
    setBreathsTransparancy(event.target.value);
  }

  const onFormRoomUrlMessageChange = (event) => {
    setFormRoomUrlMessage(event.target.value);
  }

  const onFormRoomNameMessageChange = (event) => {
    setFormRoomNameMessage(event.target.value);
  }

  const onPageTitleChange = (event) => {
    setPageTitle(event.target.value);
  }

  const onPageDescriptionChange = (event) => {
    setPageContent(event.target.value);
  }
  const onPageScriptChange = (event) => {
    setPageScript(event.target.value);
  }

  const onStartDescriptionChange = (event) => {
    setStartDescription(event.target.value);
  }

  const onPageShowChange = (event) => {
    setPageShow(event.target.checked);
  }

  const onPageEnableChange = (event) => {
    setPageEnable(event.target.checked);
  }
  

  const addContent = () => {
    const name = nameInput.current;
    const url = urlInput.current;
    if(url.value !== "" && name.value !== ""){
      if(ReactPlayer.canPlay(url.value)){
        let contentElements =[...content];
        let contentItem = {name:name.value,url:url.value};
        contentElements.push(contentItem);
        setContent(contentElements);
        url.value = "";
        name.value = "";
      }else{
        window.alert("Invalid content URL!");
      }
    }else{
      window.alert("Name and URL are required!");
    }
  }

  const deleteContent = (index) => {
    if(window.confirm("Are you sure you want to delete !")) {
      let contentElements =[...content];
      contentElements.splice(index, 1);
      setContent(contentElements);
    }
  }

  const onVideoClick = (index) => {
    setSelectURL(content[index].url);
  }

  const onSelectBgImage = imageUrl => {
    window.changeBackground(imageUrl);
    //
    let backgroundsList =[...backgrounds];
    //reset date if available for other background
    backgroundsList.forEach(function (bg) {
      if(bgDate!=null && bg.date===bgDate){
        bg.date = null;
      }
    });
    let backgroundItem = {url:imageUrl,date:bgDate};
    backgroundsList.push(backgroundItem);
    setBackgrounds(backgroundsList);
    //
    addImage();
    document.getElementById("bg-date").value = "";
    setBgDate(null);
  }

  const deleteBackground = (index) => {
    if(window.confirm("Are you sure you want to delete !")) {
      let backgroundsList =[...backgrounds];
      backgroundsList.splice(index, 1);
      setBackgrounds(backgroundsList);
    }
  }

  const previewBackground = (index) => {
      window.changeBackground(backgrounds[index].url);
  }

  const onBgRotationChange = (event) => {
    setBgRotation(event.target.value);
  }
  
  const onBgDateChange = (event) => {
    setBgDate(event.target.value);
  }

  const addImage = () => {
    if( document.getElementById("image-add").style.display === "none") {
     document.getElementById("image-add").style.display = "block";
     document.getElementById("backgrounds-list").style.display = "none";
  } else {
     document.getElementById("image-add").style.display = "none";
     document.getElementById("backgrounds-list").style.display = "block";
    }
  }

  const saveAll = () => {
    if(hostStartDelay<=0 || startDelay <= 0 || immediateStartDelay <= 0 || breathCycle <= 0 || !welcome || !title || !description || !startdescription ||  !pagecontent || !pagecontent){
      alert("Missing Configuration!");
    } else if(welcome.indexOf(REACT_APP_ROOM_NAME_VAR)===-1){      
      alert(REACT_APP_ROOM_NAME_VAR + " should be available in the welcome message!" );
    }else {
      setIsLoading(true);
      onSave();
      onSaveAdPage();
    }
  }

  const onSave = () => {
    //Save
    const roomInfo = { 
      welcome:welcome,
      title:title,
      description:description,
      content:content,
      formRoomTitle:formRoomTitle,
      formRoomUrlMessage:formRoomUrlMessage,
      formRoomNameMessage:formRoomNameMessage,
      hostStartDelay: hostStartDelay,
      startDelay:startDelay,
      immediateStartDelay:immediateStartDelay,
      breathCycle:breathCycle,
      hostBackgrounds:JSON.stringify(backgrounds),
      hostBgRotation: bgRotation,
      breathsTransparancy: breathsTransparancy
    };
    //Save room info
    saveConfiguration(ROOM_DEFAULT_INFO,roomInfo);
  }

  const onSaveAdPage = () => {
     //Save
     const addvertizmentPage = { 
       title:pagetitle,
       content:pagecontent,
       startdescription:startdescription,
       script:pagescript,
       pageshow:pageShow,
       pageenable:pageEnable
     };
     //Save configuration
     saveConfiguration(ROOM_ADD_PAGE_INFO,addvertizmentPage);
  }


  //Load default values 
  const getDefaultConfiguration = () => {
    loadConfiguration(ROOM_DEFAULT_INFO,function( info ) {
      if(info && info.length>0){
        let defaultInfo = JSON.parse(info[0].data);
        setDefaultConfiguration(defaultInfo);
        setWelcome(defaultInfo.welcome);
        setTitle(defaultInfo.title);
        setDescription(defaultInfo.description);
        setContent(defaultInfo.content != null ? defaultInfo.content : []);
        
        setFormRoomTitle(defaultInfo.formRoomTitle);
        setFormRoomUrlMessage(defaultInfo.formRoomUrlMessage);
        setFormRoomNameMessage(defaultInfo.formRoomNameMessage);
        setBreathsTransparancy(defaultInfo.breathsTransparancy);

        setHostStartDelay(defaultInfo.hostStartDelay);
        setStartDelay(defaultInfo.startDelay);
        setImmediateStartDelay(defaultInfo.immediateStartDelay);
        setBreathCycle(defaultInfo.breathCycle);
        setBgRotation (defaultInfo.hostBgRotation);
        setBackgrounds(defaultInfo.hostBackgrounds ? JSON.parse(defaultInfo.hostBackgrounds) : []);

        //Request ad page content
        if(!defaultAdPageConfiguration){
          loadConfiguration(ROOM_ADD_PAGE_INFO,function( info ) {
            if(info && info.length>0){
              let defaultInfo = JSON.parse(info[0].data);
              setDefaultAdPageConfiguration(defaultInfo);
              setPageTitle(defaultInfo.title);
              setPageContent(defaultInfo.content);
              setPageScript(defaultInfo.script);
              setStartDescription(defaultInfo.startdescription);
              setPageShow(defaultInfo.pageshow);
              setPageEnable(defaultInfo.pageenable);
            }
        })
        }
      }
    })
  }
  //Load Configuration
  const loadConfiguration = (key,onSuccess) => {
    const whereClause = `key = '${key}'`;
    let queryBuilder = Backendless.DataQueryBuilder.create().setWhereClause( whereClause );
    queryBuilder.addProperties('data');
    Backendless.Data.of( "system_configuration" ).find( queryBuilder )
    .then(onSuccess)
    .catch( function( error ) {
        console.log(error);
    });
  }

  //Save configuration 
  const saveConfiguration = (key,data) => {
    const whereClause = `key = '${key}'`;
    data = {data:JSON.stringify(data)};
    setTimeout(function(){
        Backendless.Data.of('system_configuration').bulkUpdate(whereClause,data)
        .then(obj => {
          setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
          console.log(error);
          alert("Please try again! \n " + error);
        })
      },100)
  }

    //Requests default room info if not requested
    if(!defaultConfiguration){
      getDefaultConfiguration();
    }


  return (
    <div>
            <div className="scroll-container">
            
            <div className=" settings-box">
              <fieldset  className="settings-form">
                  <legend className="title">Create Room Form Configuration</legend>
                  <div className="settings-form-content">
                    <div>
                      <label>Form Title</label>
                      <input type="text" value={formRoomTitle} maxLength="100" required placeholder="Form Title" style={{width:"90%"}} onChange={onFormRoomTitleChange} />              
                    </div>
                    <div>
                      <label>Meeting Link Input Message</label>
                      <input type="text" value={formRoomUrlMessage} maxLength="100" required placeholder="Meeting Link Input Message" style={{width:"90%"}} onChange={onFormRoomUrlMessageChange} />              
                    </div>
                    <div>
                    <label>Name Input Message</label>
                      <input type="text" value={formRoomNameMessage} maxLength="100" required placeholder="Title Input Message" style={{width:"90%"}} onChange={onFormRoomNameMessageChange} />              
                    </div>
                  </div>
              </fieldset>
            </div>

            <div className=" settings-box">
            <fieldset  className="settings-form">
                <legend className="title">Room Default Configuration</legend>
                <div className="settings-form-content">
                <div>
                    <label>Welcome Message</label>
                    <input type="text" value={welcome} maxLength="100" required placeholder="Welcome Message" style={{width:"90%"}} onChange={onWelcomeChange} />
                    <div className="info">{REACT_APP_ROOM_NAME_VAR} is mandotary and will be replaced by the exact room name.</div>
                  </div>
                  <div>
                    <label>Room Title</label>
                    <input type="text" value={title} maxLength="100" required placeholder="Title" style={{width:"90%"}} onChange={onTitleChange} />              
                  </div>
                  <div>
                  <label>Room Description</label>
                    <textarea  rows={3}  value={description} maxLength="125" required placeholder="Description" style={{width:"90%"}}  onChange={onDescriptionChange} />  
                  </div>
                  
                </div>
            </fieldset>
            </div>

            <div className=" settings-box">
            <fieldset  className="settings-form">
                <legend className="title">Videos Content</legend>
              <div className="settings-form-content">
                    <div className="content-listing">
                    <input ref={nameInput} type="text" maxLength="100"  placeholder="Content Title" style={{width:"80%", display:"inline"}}  />
                    <input ref={urlInput} type="text" maxLength="100"  placeholder="Enter Video URL" style={{width:"80%", display:"inline"}}  /> 
                    <div onClick={addContent} className="addVideoButton">+</div> 
                    <div className="content-items">
                      <ul>
                      {

                        content && content.length > 0 ?
                        content.map((content_item,index) => {
                          return (
                              <li key={index} >
                                <div className="content-item">
                                  <div onClick={() => {onVideoClick(index)}} className="video-icon"></div>
                                  <div onClick={() => {onVideoClick(index)}}  className="video-title" title={content_item.name}>{content_item.name}</div>
                                  <div onClick={() => {deleteContent(index)}} className="deleteVideoButton">X</div>
                                </div>
                              </li>
                          )
                        })
                        :
                        <li className="nocontent">
                          <div> no content added !</div>
                        </li>
                      }
                      </ul>
                      </div>
                    </div>
                  </div>
                  </fieldset>
                  <VideoPlayer url={selectURL} onClose = {()=>{setSelectURL(null)}} mode="video-full-mode"/>
                  </div>
            
                  <div className="settings-box">
                    <fieldset  className="settings-form">
                      <legend className="title">Host Background Image</legend>
                      <div className="settings-form-content">
                      
                          <div className="form-item-full">
                            <label>Background Rotate every X minutes</label>
                            <input id="bg-rotation" type="number" value={bgRotation} required placeholder="minutes" style={{width:"90%"}} onChange={onBgRotationChange} />
                          </div>
                            
                            <button className="add-image outline" onClick={addImage}>Add Image</button>

                            <fieldset id="image-add" style={{ display: "none" }}>
                              <div className="form-item-full">
                                <label>Select Date (Keep empty for random rotation)</label>
                                <input id="bg-date" type="date" value={bgDate} placeholder="Date" style={{width:"90%"}} onChange={onBgDateChange} />
                              </div>

                              <div className="form-item-full">
                              <label>Select Image</label>
                                <div style={{ display: "flex" }}>
                                  <div className="bg-image-selector">
                                  {
                                    // eslint-disable-next-line
                                    <Unsplash
                                      height={300}
                                      accessKey={REACT_APP_UNSPLASH_ACCESS_KEY}
                                      applicationName="unsplash_react"
                                      Uploader={InsertIntoApplicationUploader}
                                      photoRatio={16 / 9}
                                      preferredSize={{ width: 1920, height: 1440 }}
                                      onFinishedUploading={onSelectBgImage}
                                    />
                                  }
                                  </div>
                                </div>
                                </div>
                                </fieldset>
                              
                              <div id="backgrounds-list">
                            {backgrounds && backgrounds.length > 0 && <div className="form-item-full">
                              <div className="backgrounds-listing content-listing">
                                <div className="content-items">
                                  <ul>
                                  {
                                    backgrounds.map((bg_item,index) => {
                                      return bg_item && bg_item.url ? (
                                          <li key={index} >
                                            <div className="content-item bg-item" id={"content-item-"+index}>
                                              <img onClick={() => {previewBackground(index)}} src={bg_item.url}  alt="background" align="absmiddle" />
                                              <div  className="bg-date" title={bg_item.date}>{bg_item.date}</div>
                                              <div onClick={() => {deleteBackground(index)}} className="deleteBgButton">X</div>
                                            </div>
                                          </li>
                                      ) : (<div key={index}></div>)
                                    })
                                  } 
                                  </ul>
                                </div>
                              </div>
                          </div>}
                          </div>
                          

                      </div>
                    </fieldset>
                  </div>
            
            <div className=" settings-box">
            <fieldset  className="settings-form">
                <legend className="title">Meditation Configuration</legend>
                <div className="settings-form-content">

                  <div className="form-row">                
                    <div className="form-column">
                      <label>Breaths Prompt Transparency</label>
                      <input type="number"  value={breathsTransparancy} required  min={0} max={100} style={{width:"90%"}} onChange={onBreathsTransparencyChange} /> 
                      <div className="info">Percentage Value</div>     
                    </div>

                    <div className="form-column">
                      <label>Breath Cycle</label>
                      <input type="number" value={breathCycle}  required  style={{width:"90%"}} onChange={onBreathCycleChange} />
                      <div className="info">Value in Seconds</div>              
                    </div>
                    </div>
                  
                    <div className="form-row">
                  <div className="form-column">
                    <label>Room Start after</label>
                    <input type="number"  value={startDelay} required  style={{width:"90%"}} onChange={onStartDelayChange} /> 
                    <div className="info">Value in number of Breaths</div>     
                  </div>
                  <div className="form-column">
                    <label>Room Immediate Start after</label>
                    <input type="number" value={immediateStartDelay}  required  style={{width:"90%"}} onChange={onImmediateStartDelayChange} />              
                    <div className="info">Value in number of Breaths</div>     
                  </div>
                  </div>
                  
                  <div className="form-row">
                  <hr></hr>
                  <div>
                    <label>Host Start Delay</label>
                    <input type="number"  value={hostStartDelay} required  style={{width:"90%"}} onChange={onHostStartDelayChange} /> 
                    <div className="info">Value in number of Breaths</div>
                  </div>
                  </div>
                </div>
            </fieldset>
            </div>

            <div className=" settings-box">
            <fieldset  className="settings-form">
                <legend className="title">Advertisement Page Configuration</legend>
                <div className="settings-form-content">
                <div>
                    <input id="c0" className="checkbox" type="checkbox" checked={pageEnable} required onChange={onPageEnableChange} /> 
                    <label htmlFor="c0">Enable Advertisement page</label>        
                  </div>
                  <div disabled="disabled">
                  <div>
                    <input id="c1" className="checkbox" type="checkbox" checked={pageShow} required onChange={onPageShowChange} /> 
                    <label htmlFor="c1">Show Advertisement page if meeting started</label>        
                  </div>
                <br></br>
                <div>
                  <label>Room Start Message</label>
                    <textarea  rows={3}  value={startdescription} maxLength="125" required placeholder="Start Message" style={{width:"90%"}}  onChange={onStartDescriptionChange} />  
                  </div>
                 <div>
                    <label>Page Title</label>
                    <input type="text" value={pagetitle} maxLength="100" required placeholder="Page Title" style={{width:"90%"}} onChange={onPageTitleChange} />              
                  </div>
                  <div>
                  <label>Page Content (HTML)</label>
                    <textarea  rows={10}  value={pagecontent} required placeholder="HTML Content" style={{width:"90%"}}  onChange={onPageDescriptionChange} />  
                  </div>
                  <div>
                  <label>Page Script (JAVASCRIPT)</label>
                    <textarea  rows={10}  value={pagescript} placeholder="//Add You script without <script> tag" style={{width:"90%"}}  onChange={onPageScriptChange} />  
                  </div>
                  </div>
                </div>
            </fieldset>
            </div>

            </div>
            <div className="settings-footer">
            {isLoading
              ?<button className="main autowidth" id="save-button" disabled>Saving...</button>
              :<button className="main autowidth" id="save-button" onClick={saveAll}>Save All</button>
            }
            </div>
    </div>
  );
};

export default SysConifg;