import { useState } from "react";
import Backendless from 'backendless';
const { REACT_APP_ADMIN_USER } = process.env;


const LoginPanel = ({onLogin}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState(null);

  const onChangePassword = (event) => {
    setPassword(event.target.value);
  }

  const login = () => {
    if(password!=null && password.length>0){
      setIsLoading(true);
      //Call authenticate service
      Backendless.UserService.login(REACT_APP_ADMIN_USER,password,true)
      .then( function( user ) {
        setIsLoading(false);
        onLogin();
      }).catch( function( error ) {
        setIsLoading(false);
        alert(error);
      });
    }else{
      alert("Password is required!")
    }
  }

  const handleKeypress = e => {
    //it triggers by pressing the enter key
    if (e.charCode === 13) {
      login();
    }
};
  
  return  (
    <div className="room-created-box blur-bg">
      <div className="room-created-info">
          <div className="title-info"><span>Admin Login</span></div>
          <h3 className="title">Let's Start</h3>
          <input type="password" name="password" onChange={onChangePassword} placeholder="Password" onKeyPress={handleKeypress}/>
          {
          (isLoading?<span>Loading...</span>: <button className="main" onClick={login}>Login</button>)
          }
      </div>
    </div>
  );
};

export default LoginPanel;
