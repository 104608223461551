import React, { useState } from "react";
//import TimezoneSelect, {allTimezones} from "react-timezone-select";
//import moment from "moment-timezone";
import Backendless from 'backendless';
import Unsplash , { InsertIntoApplicationUploader } from "../yoyuma-common/Unsplash";
const { REACT_APP_UNSPLASH_ACCESS_KEY} = process.env;
const WebsiteConfig = () => {

  const WEBSITE_INFO = "website_info";

  // State  
  const [isLoading, setIsLoading] = useState(false);
  const [defaultConfiguration, setDefaultConfiguration] = useState(null);
  const [roomLink, setRoomLink] = useState("");
  const [howItWorksLink, setHowItWorksLink] = useState("");
  const [contactUsLink, setcContactUsLink] = useState("");
  const [content1, setContent1] = useState("");
  const [content2, setContent2] = useState("");
  const [content3, setContent3] = useState("");
  const [bg1, setBg1] = useState("");
  const [bg2, setBg2] = useState("");
  const [bg3, setBg3] = useState("");
  const [videoURL, setVideoURL] = useState("");
  const [step1Title, setStep1Title] = useState("");
  const [step1Content, setStep1Content] = useState("");
  const [step2Title, setStep2Title] = useState("");
  const [step2Content, setStep2Content] = useState("");
  const [step3Title, setStep3Title] = useState("");
  const [step3Content, setStep3Content] = useState("");

  const onRoomLinkChange = (event) => {
    setRoomLink(event.target.value);
  }
  const onHowItWorksLinkChange = (event) => {
    setHowItWorksLink(event.target.value);
  }
  const onContactUsLinkChange = (event) => {
    setcContactUsLink(event.target.value);
  }
  const onContent1Change = (event) => {
    setContent1(event.target.value);
  }
  const onContent2Change = (event) => {
    setContent2(event.target.value);
  }
  const onContent3Change = (event) => {
    setContent3(event.target.value);
  }
  const onBg1Change = imageUrl => {
    setBg1(imageUrl);
  }
  const onBg2Change = imageUrl => {
    setBg2(imageUrl);
  }
  const onBg3Change = imageUrl => {
    setBg3(imageUrl);
  }

  const onVideoURLChange = (event) => {
    setVideoURL(event.target.value);
  }

  const onStep1TitleChange = (event) => {
    setStep1Title(event.target.value);
  }

  const onStep1ContentChange = (event) => {
    setStep1Content(event.target.value);
  }

  const onStep2TitleChange = (event) => {
    setStep2Title(event.target.value);
  }

  const onStep2ContentChange = (event) => {
    setStep2Content(event.target.value);
  }
  

  const onStep3TitleChange = (event) => {
    setStep3Title(event.target.value);
  }

  const onStep3ContentChange = (event) => {
    setStep3Content(event.target.value);
  }
  

  


  const isValidString = (str) => {
    return str!=null && str!=="";
  }

  const saveAll = () => {
    
    if( !isValidString(roomLink) || 
        !isValidString(howItWorksLink) || 
        !isValidString(contactUsLink) || 
        !isValidString(content1) || 
        !isValidString(content2) || 
        !isValidString(content3) || 
        !isValidString(bg1) || 
        !isValidString(bg2)  || 
        !isValidString(bg3) ||
        !isValidString(videoURL) ||
        !isValidString(step1Title) ||
        !isValidString(step1Content) ||
        !isValidString(step2Title) ||
        !isValidString(step2Content) ||
        !isValidString(step3Title) ||
        !isValidString(step3Content) 
      ){
      alert("Missing Configuration!");
    } else{
      setIsLoading(true);
      onSave();
    }
  }

  const onSave = () => {
    //Save
    const websiteInfo = { 
      roomLink:roomLink,
      howItWorksLink:howItWorksLink,
      contactUsLink:contactUsLink,
      content1:content1,
      content2:content2,
      content3:content3,
      bg1:bg1,
      bg2:bg2,
      bg3:bg3,
      videoURL:videoURL,
      step1Title:step1Title,
      step1Content:step1Content,
      step2Title:step2Title,
      step2Content:step2Content,
      step3Title:step3Title,
      step3Content:step3Content
    };
    //Save room info
    saveConfiguration(WEBSITE_INFO,websiteInfo);
  }


  //Load default values 
  const getDefaultConfiguration = () => {
    loadConfiguration(WEBSITE_INFO,function( info ) {
      if(info && info.length>0){
        let defaultInfo = JSON.parse(info[0].data);
        setRoomLink(defaultInfo.roomLink);
        setHowItWorksLink(defaultInfo.howItWorksLink);
        setcContactUsLink(defaultInfo.contactUsLink);
        setContent1(defaultInfo.content1);
        setContent2(defaultInfo.content2);
        setContent3(defaultInfo.content3);
        setBg1(defaultInfo.bg1);
        setBg2(defaultInfo.bg2);
        setBg3(defaultInfo.bg3);
        setVideoURL(defaultInfo.videoURL);
        setStep1Title(defaultInfo.step1Title);
        setStep1Content(defaultInfo.step1Content);
        setStep2Title(defaultInfo.step2Title);
        setStep2Content(defaultInfo.step2Content);
        setStep3Title(defaultInfo.step3Title);
        setStep3Content(defaultInfo.step3Content);
        setDefaultConfiguration(defaultInfo);
      }
    })
  }
  //Load Configuration
  const loadConfiguration = (key,onSuccess) => {
    const whereClause = `key = '${key}'`;
    let queryBuilder = Backendless.DataQueryBuilder.create().setWhereClause( whereClause );
    queryBuilder.addProperties('data');
    Backendless.Data.of( "system_configuration" ).find( queryBuilder )
    .then(onSuccess)
    .catch( function( error ) {
        console.log(error);
    });
  }

  //Save configuration 
  const saveConfiguration = (key,data) => {
    const whereClause = `key = '${key}'`;
    data = {data:JSON.stringify(data)};
    setTimeout(function(){
        Backendless.Data.of('system_configuration').bulkUpdate(whereClause,data)
        .then(obj => {
          setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
          console.log(error);
          alert("Please try again! \n " + error);
        })
      },100)
  }

    //Requests default room info if not requested
    if(!defaultConfiguration){
      getDefaultConfiguration();
    }


  return (
    <div>
            <div className="scroll-container">
            
            <div className="settings-box">
              <fieldset  className="settings-form settings-form-large">
                  <legend className="title">General Configuration</legend>
                  <div className="settings-form-content">
                    <div>
                      <label>Create Room Link</label>
                      <input type="text" value={roomLink} maxLength="200" required placeholder="" style={{width:"90%"}} onChange={onRoomLinkChange} />              
                    </div>
                    <div>
                      <label>How It Works Link</label>
                      <input type="text" value={howItWorksLink} maxLength="200" required placeholder="" style={{width:"90%"}} onChange={onHowItWorksLinkChange} />              
                    </div>
                    <div>
                    <label>Contact Us Link</label>
                      <input type="text" value={contactUsLink} maxLength="200" required placeholder="" style={{width:"90%"}} onChange={onContactUsLinkChange} />              
                    </div>
                  </div>
              </fieldset>
            </div>

            <div className=" settings-box">
            <fieldset  className="settings-form settings-form-large">
                <legend className="title">Section 1 Configuration</legend>
                <div className="settings-form-content">
                <div>
                  <label>Content</label>
                    <textarea  rows={3}  value={content1} required placeholder="Content" style={{width:"90%"}}  onChange={onContent1Change} />  
                  </div>

                  <div className="form-item-full">
                  <div className="bg-holder">
                              <label>Select Background</label>
                              {bg1 && <div className="form-item-full selected-img "><img src={bg1} alt="Selected Background"/></div>}    
                                <div style={{ display: "flex" }}>
                                  <div className="bg-image-selector">
                                  {
                                    // eslint-disable-next-line
                                    <Unsplash
                                      height={300}
                                      accessKey={REACT_APP_UNSPLASH_ACCESS_KEY}
                                      applicationName="unsplash_react"
                                      Uploader={InsertIntoApplicationUploader}
                                      photoRatio={16 / 9}
                                      preferredSize={{ width: 1920, height: 1440 }}
                                      onFinishedUploading={onBg1Change}
                                    />
                                  }
                                  </div>
                                </div>
                                </div>
                                </div>
            
                  
                </div>
            </fieldset>
            </div>

            
                  <div className="settings-box">
                    <fieldset  className="settings-form settings-form-large">
                      <legend className="title">Section 2 Configuration</legend>
                      <div className="settings-form-content ">
                      <div>
                        
                  <label>Content</label>
                    <textarea  rows={3}  value={content2} required placeholder="Description" style={{width:"90%"}}  onChange={onContent2Change} />  
                  </div>      
                              <div className="bg-holder">
                              {bg2 && <div className="form-item-full selected-img "><img src={bg2} alt="Selected Background"/></div>}     
                              <div className="form-item-full">
                              <label>Select Background</label>
                               
                                <div style={{ display: "flex" }}>
                                  <div className="bg-image-selector">
                                  {
                                    // eslint-disable-next-line
                                    <Unsplash
                                      height={300}
                                      accessKey={REACT_APP_UNSPLASH_ACCESS_KEY}
                                      applicationName="unsplash_react"
                                      Uploader={InsertIntoApplicationUploader}
                                      photoRatio={16 / 9}
                                      preferredSize={{ width: 1920, height: 1440 }}
                                      onFinishedUploading={onBg2Change}
                                    />
                                  }
                                  </div>
                                </div>
                                </div>
                                </div>

                      </div>
                    </fieldset>
                  </div>


                  <div className="settings-box">
                    <fieldset  className="settings-form settings-form-large">
                      <legend className="title">Section 3 Configuration</legend>
                      <div className="settings-form-content">
                      <div>
                  <label>Content</label>
                    <textarea  rows={3}  value={content3} required placeholder="Description" style={{width:"90%"}}  onChange={onContent3Change} />  
                  </div>

                      <div>
                        <label>Video URL</label>
                        <input type="text" value={videoURL} maxLength="200" required placeholder="Title" style={{width:"90%"}} onChange={onVideoURLChange} />              
                      </div>
                  
                      <div>
                        <label>Step 1 Content</label>
                        <input type="text" value={step1Title} maxLength="200" required placeholder="Title" style={{width:"90%"}} onChange={onStep1TitleChange} />  
                        <textarea  rows={2}  value={step1Content} required placeholder="Description" style={{width:"90%"}}  onChange={onStep1ContentChange} />              
                      </div>

                      <div>
                        <label>Step 2 Content</label>
                        <input type="text" value={step2Title} maxLength="200" required placeholder="Title" style={{width:"90%"}} onChange={onStep2TitleChange} />  
                        <textarea  rows={2}  value={step2Content} required placeholder="Description" style={{width:"90%"}}  onChange={onStep2ContentChange} />              
                      </div>

                      <div>
                        <label>Step 3 Content</label>
                        <input type="text" value={step3Title} maxLength="200" required placeholder="" style={{width:"90%"}} onChange={onStep3TitleChange} />  
                        <textarea  rows={2}  value={step3Content} required placeholder="Description" style={{width:"90%"}}  onChange={onStep3ContentChange} />              
                      </div>
                              <div className="bg-holder">
                              {bg3 && <div className="form-item-full selected-img "><img src={bg3} alt="Selected Background"/></div>}     
                              <div className="form-item-full">
                              <label>Select Background</label>
                               
                                <div style={{ display: "flex" }}>
                                  <div className="bg-image-selector">
                                  {
                                    // eslint-disable-next-line
                                    <Unsplash
                                      height={300}
                                      accessKey={REACT_APP_UNSPLASH_ACCESS_KEY}
                                      applicationName="unsplash_react"
                                      Uploader={InsertIntoApplicationUploader}
                                      photoRatio={16 / 9}
                                      preferredSize={{ width: 1920, height: 1440 }}
                                      onFinishedUploading={onBg3Change}
                                    />
                                  }
                                  </div>
                                </div>
                                </div>
                                </div>
                          

                      </div>

                      

                    </fieldset>

                    

                  </div>

            </div>
            <div className="settings-footer">
            {isLoading
              ?<button className="main autowidth" id="save-button2" disabled>Saving...</button>
              :<button className="main autowidth" id="save-button2" onClick={saveAll}>Save All</button>
            }
            </div>
    </div>
  );
};

export default WebsiteConfig;