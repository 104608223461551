import Backendless from 'backendless';
import { useState } from 'react';
import MainMenu from './MainMenu';
import SysConifg from './SysConifg';
import WebsiteConfig from './WebsiteConfig';

const AdminPanel = ({onLogout,mode}) => {

  const [isLoading, setIsLoading] = useState(false);

  const logout = () => {
    Backendless.UserService.logout()
    .then( function( ) {
      setIsLoading(false);
      onLogout();
    }).catch( function( error ) {
      setIsLoading(false);
      alert(error);
    });
  }

  return  (
    <div>
    {!isLoading && <div className="welcome" ><span className="user-icon"><i class="fa fa-user-circle-o" aria-hidden="true"></i></span><span id="editprofile" class="login-button">Welcome Admin!</span> | <span class="logout-button" onClick={logout}>logout</span></div> }
      <div className="admin-panel blur-bg">
        <div>
         {mode === "ADMIN" && <SysConifg />}
         {mode === "WEBSITE" && <WebsiteConfig />}
         <MainMenu mode={mode}/>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
